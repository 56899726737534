<template>
  <main>
  <AppHeader />
    <section class="container-fluid bg-white">
      <div class="container pt-3 pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8" >
            <h1 class="mb-1 main-header">Kolari toisen auton kanssa</h1>
            <p class="">Ohjeet vahingon sattuessa:</p>
          </div>
        </div>
      </div>

      <div class="container pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8" >
            <div class="card">
              <img src="img/kolari_tilannekartoitus.jpg" alt="Tarkista aluksi onko joku loukkaantunut. Jos on, soita 112 ja pyydä ensiapua ja poliisi.">
              <div class="caption with-order">
                <span class="order">1</span>
                <p>Selvitä ensimmäiseksi, onko kukaan loukkaantunut. Jos törmäyksestä on aiheutunut henkilövahinkoja, soita 112 ja pyydä onnettomuuspaikalle ensiapu ja poliisi.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/kolari_varoituskolmio.jpg" alt="Varota muita! Laita varoituskolmio tielle noin 100 m ennen kolaripaikkaa.">
              <div class="caption with-order">
                  <span class="order">2</span>
                  <p>Varoita muita! Aseta varoituskolmio tien reunaan reilu 100 metriä ennen kolaripaikkaa.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/tormays_tiedot_talteen.jpg" alt="Ota talteen vastapuolen henkilötiedot, rekisterinumero, vakuutusyhtiö sekä todistajien tiedot.">
              <div class="caption with-order">
                <span class="order">3</span>
                <p>Ota vastapuolen henkilötiedot talteen hänen ajokortistaan. Kirjoita ylös rekisterinumero, vakuutusyhtiö ja muista ottaa talteen myös mahdollisten todistajien henkilötiedot.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/tormays_kuvaa_kolari.jpg" alt="Kuvaa kännykkäkameralla kolaritilanne. Ota reilusti kuvia.">
              <div class="caption with-order">
                <span class="order">4</span>
                <p>Dokumentoi törmäyspaikka valokuvin esim. yleiskuva, mahdolliset jarrutusjäljet, törmäyspaikka, auton vahingot. Ota reilusti kuvia.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/kolari_henkilovahinko.jpg" alt="Henkilövahingoissa on aina kutsuttava poliisi. Jos vahinko on aineellinen ja vähäinen, poliisia ei tarvita.">
              <div class="caption with-order">
                <span class="order">5</span>
                <p>Kutsu poliisi aina, jos kolarissa on sattunut henkilövahinkoja. Jos vahinko on aineellinen ja syyllisyys on selvä, poliisia ei välttämättä tarvita.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/tormays_hinausauto.jpg" alt="Tarvitaanko hinausauto? Jos tarvitset, soita Toyota-palvelunumeroon 0800 13 663.">
              <div class="caption with-order">
                <span class="order">6</span>
                <p>Mikäli autosi on vahingoittunut siten, ettei matkan jatkaminen onnistu, tilaa hinausauto Toyota-palvelunumerosta 0800 13 663.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/tormays_varoitusvalo.jpg" alt="Turvalaitteet ovat saattaneet törmäyksessä vaurioitua. Tarkistuta ne vauriotarkastuksessa.">
              <div class="caption with-order">
                <span class="order">7</span>
                <p>Törmäystilanteessa autosi turvalaitteet ovat saattaneet vahingoittua. Mikäli autosi on ajokunnossa, varaa aika ja tule Vauriotarkastukseemme. Näin varmistat, että autosi turvalaitteet toimivat luotettavalla tavalla.</p>
              </div>
            </div>

            <div class="card">
              <img src="img/tormays_vahinkoilmoitus.jpg" alt="Tee vahinkotarkastuksen yhteydessä vahinkoilmoitus vakuutusyhtiöön. Toyota auttaa tässäkin.">
              <div class="caption with-order">
                <span class="order">8</span>
                <p>Tee vauriotarkastuksen yhteydessä vahinkoilmoitus vakuutusyhtiöön. Toyota-liike auttaa sinua ilmoituksen teossa.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AppFooter v-if="isMobile()"
      :buttonLocation="false"
      :buttonMaintenance="false"
    />
    <DesktopFooter v-else />
    <FooterVakuutus v-if="!isMobile()"/>
    <FooterKorjaamoluotto v-if="!isMobile()"/>

    <BackToTop v-if="!isMobile()"/>

  </main>
</template>

<script>
import useGlobalHelper from './../composables/useGlobalHelper'
import AppHeader from './../components/AppHeader'
import AppFooter from './../components/AppFooter'
import DesktopFooter from './../components/DesktopFooter'
import FooterVakuutus from './../components/FooterVakuutus'
import FooterKorjaamoluotto from './../components/FooterKorjaamoluotto'
import BackToTop from './../components/BackToTop'


export default {
  name: "Kolari",
  setup() {
    const { isMobile, isIframe } = useGlobalHelper();
    return {
      isMobile,
      isIframe,
    };
  },
    components: {
      AppHeader:AppHeader,
      AppFooter:AppFooter,
      DesktopFooter:DesktopFooter,
      FooterVakuutus:FooterVakuutus,
      FooterKorjaamoluotto:FooterKorjaamoluotto,
      BackToTop:BackToTop,
    }
}

</script>