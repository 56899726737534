<template>
  <section id="footer-desktop">
    <div class="container text-white py-4">
      <div v-if="buttonCall" class="intro-number mb-3">
        <i class="icon-phone" aria-hidden="true"></i> Soita 24/7 maksuttomaan
        numeroon
        <a @click="call('0800 13 663')" class="call-number">0800 13 663</a>
      </div>

      <router-link v-if="buttonDealers" tag="button" class="btn" to="/liike">
        <i class="icon-map-marker pull-left" aria-hidden="true"></i> Etsi lähin
        Toyota-korikorjaamo
      </router-link>

      <a
        v-if="buttonCheck && checkEnabled"
        href="https://toyotahuoltovaraus.fi/varaa/vauriotarkastus"
        target="_blank"
        class="btn"
      >
        <i class="icon-calendar pull-left" aria-hidden="true"></i> Varaa
        vauriotarkastus
      </a>
    </div>
  </section>
</template>

<script>
import useGlobalHelper from './../composables/useGlobalHelper'

export default {
  name: "DesktopFooter",
    setup() {
    const { call, checkEnabled } = useGlobalHelper();
    return {
      call,
      checkEnabled
    };
  },
  props: {
    /** Buttons **/
    buttonCall: {
      type: Boolean,
      default: true,
    },

    buttonCheck: {
      type: Boolean,
      default: true,
    },

    buttonDealers: {
      type: Boolean,
      default: true,
    },

    buttonLocation: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
