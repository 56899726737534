<template>
  <div>
    <AppHeader />

    <section
      id="mapContainer"
      class="basemap"
      style="width: 100%; height: 450px"
    ></section>
    <div
      v-if="loading"
      class="container-fluid text-align--center c-pt-half fixed-top"
    >
      <p>
        <i
          class="fa fa-circle-o-notch fa-spin fa-2x fa-fw text-red"
          aria-hidden="true"
        ></i>
        <span class="sr-only">Loading...</span>
      </p>
    </div>

    <section class="container-fluid bg-white">
      <div class="container pt-2 pb-1">
        <h1 class="mb-2 main-header">Etsi Toyota-liike</h1>
        <form action="#" v-on:submit.prevent="search">
          <div class="input-group search-group">
            <input
              class="form-control mb-3"
              type="search"
              v-model="search_input"
              placeholder="Hae nimellä, paikalla tai postinumerolla"
            />
            <span class="input-group-btn">
              <button
                @click.prevent="search"
                class="btn btn-default search"
                type="button"
              >
                <i class="icon-search"></i>
              </button>
              <input type="submit" value="Hae" style="display: none" />
            </span>

            <button
              v-if="isMobile()"
              @click="nearMe()"
              class="btn btn-default c-btn-round locate"
              type="button"
            >
              <i class="icon-locate"></i>
            </button>
            <button
              v-else
              @click="nearMe()"
              class="btn btn-default locate desktop"
              type="button"
            >
              <i class="icon-locate pull-left"></i> Etsi läheltäni
            </button>
          </div>
        </form>
      </div>

      <div v-if="error" class="container pb-2">
        <p>{{ error }}</p>
      </div>

      <div v-if="filtered_dealers && !loading" class="container pb-2">
        <div
          v-for="(dealer, index) in filtered_dealers"
          :key="index"
          class="row dealer-list-item text-left border border-top-0 b border-right-0 border-left-0 pb-1 mb-1"
        >
          <div class="col">
            <a
              :href="dealer.url.WebsiteURL"
              target="_blank"
              class="text-decoration--none"
              >{{ dealer.name }}</a
            ><br />
            <span v-if="dealer.distance" class="support-text"
              >{{ Math.round(dealer.distance) }} km</span
            >
          </div>

          <div class="col d-flex justify-content-end">
            <button
              v-if="isMobile()"
              @click="call(getPhone(dealer))"
              type="button"
              class="c-btn-round text-secondary"
            >
              <i class="icon-phone" aria-hidden="true"></i>
            </button>

            <div v-else class="desktop-phone-email">
              <a @click="call(getPhone(dealer))" class="desktop-call">
                <i class="icon-phone" aria-hidden="true"></i>
                {{ getPhone(dealer) }}
              </a>
              <a @click="email(getEmail(dealer))" class="desktop-email">
                <i class="icon-email" aria-hidden="true"></i>
                {{ getEmail(dealer) }}
              </a>
            </div>

            <button
              @click="centerMap(dealer.addresses[0].geoLocation)"
              type="button"
              class="c-btn-round text-secondary cursor--pointer"
            >
              <i class="icon-map-marker" aria-hidden="true"></i>
            </button>

            <button v-if="search_location" @click="mapRoute(dealer)" type="button" class="c-btn-round text-secondary">
                <i class="icon-car" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div
          v-if="!search_submit"
          class="mt-3 text-center"
        >
          <button @click="showMore" class="btn no-shadow">Näytä lisää</button>
        </div>
      </div>
    </section>
    <AppFooter
      v-if="isMobile()"
      :showText="false"
      :buttonMaintenance="false"
      :buttonCheck="false"
      :buttonDealers="false"
    />

    <DesktopFooter v-else :buttonDealers="false" />

    <BackToTop v-if="!isMobile()" />
  </div>
</template>

<script>
import _ from 'lodash'
import config from './../plugins/config'
import useGlobalHelper from './../composables/useGlobalHelper'
import AppHeader from './../components/AppHeader'
import AppFooter from './../components/AppFooter'
import DesktopFooter from './../components/DesktopFooter'
import {mapState} from 'vuex';
import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import BackToTop from './../components/BackToTop'

export default {
  name: "Liike",
  setup() {
    const { isMobile, call, email, scrollToTop } = useGlobalHelper()
    return {
      isMobile,
      call,
      email,
      scrollToTop
    }
  },
  data() {
    return {
      // map
      map: null,
      accessToken: config.mapBoxToken,
      mapStyle: "mapbox://styles/mapbox/streets-v11",
      zoom: 4,
      center: { lat: 63.95, lng: 25.918 },
      // search
      search_input: '',
      search_submit: false,
      // loading
      loading: true,
      error: false,

      a2dAuth: config.a2dAuth,
      filtered_dealers: null,
      jmGeoJSON: { jm: [] },
      search_location: null,

      dealers_filtered: [],
      dealer: [],
    }
  },
  computed: {
    ...mapState({
      location: state => state.location,
      dealers: state => state.dealers
    })
  },
  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    DesktopFooter: DesktopFooter,
    BackToTop: BackToTop
  },
  created() {
    // Get Dealers from A2D
    axios
      .get("https://a2d-proxy.lib.toyota.fi/api/data/by_city/", {
        headers: {
          Authorization: this.a2dAuth,
        },
      })
      .then(response => {
        this.dealers = response.data
        this.dealers_filtered = response.data
        this.runBind(response.data)
      })
      .then(() => {
        // Create Map
        mapboxgl.accessToken = this.accessToken;
        this.map = new mapboxgl.Map({
          container: "mapContainer",
          style: this.mapStyle,
          center: this.center,
          zoom: this.zoom,
          minZoom: 4,
          maxZoom: 15,
        })

        // Add Map Controls
        this.map.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false,
          }),
          "top-left"
        )

      // Add Geolocate 
      const geolocate = new mapboxgl.GeolocateControl({
        fitBoundsOptions: {
            zoom: 11,
        },
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })

      // Add the Geolocate control to the map.
      this.map.addControl(geolocate, "top-left")

      //at Map load locate user using Geolocate 
      this.map.on('load', function() {
        geolocate.trigger()
      })
      
        // Load marker image and others
        const _self = this;
        const geodata = _self.jmGeoJSON.jm

        _self.map.on("load", function () {
          // Load Markers
          _self.map.loadImage(
            "img/markers/marker-liike.png",
            function (error, image) {
              if (error) throw error;
              _self.map.addImage("marker-liike", image)
            }
          );

          // Add a GeoJSON Source
          _self.map.addSource("jm", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: geodata
            },
          })

          // Add a Symbol layer
          _self.map.addLayer({
            id: "jm",
            type: "symbol",
            source: "jm",
            layout: {
              "icon-image": "marker-liike",
              "icon-offset": [0, -20],
              "icon-allow-overlap": true,
            },
          })

          // Center the Map on the Coordinates of Any clicked Symbol from the 'Symbols' layer.
          _self.map.on("click", "jm", function (e) {
            _self.map.flyTo({
              center: e.features[0].geometry.coordinates,
              zoom: 12,
            })
          })

          // When a click event occurs on a feature in the places layer, open a popup at the
          // location of the feature, with description HTML from its properties.
          _self.map.on("click", "jm", function (e) {
            let coordinates = e.features[0].geometry.coordinates.slice()
            let webAddress = e.features[0].properties.webAddress
            webAddress = webAddress.replace(/[[\]'"]+/g, '')
            console.log(webAddress)
            let description = `
              <div>
                <h5 class="py-1 mb-1"> ${e.features[0].properties.companyName}</h5>
                <p class="support-text">
                  <span class="icon-map-marker"></span>
                  <span>${e.features[0].properties.address}</span>
                </p>
                <hr/>
                <p class="support-text">
                  <a href="tel:${e.features[0].properties.phone}">
                    <span class="icon-phone"></span>
                    <span>${e.features[0].properties.phone}</span>
                  </a>
                </p>
                <hr/>
                <p class="support-text">
                  <a href="mailto:${e.features[0].properties.email}">
                    <span class="icon-email"></span>
                    <span> ${e.features[0].properties.email}</span>
                  </a>
                </p>
                <hr/>
                <p class="support-text">
                  <a href="${webAddress}" target="_blank">
                    <span class="icon-external-link"></span>
                    <span> ${webAddress}</span>
                  </a>
                </p>
              </div>
            `
              
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
            }

            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(_self.map)
          })

          // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
          _self.map.on("mouseenter", "jm", function () {
            _self.map.getCanvas().style.cursor = "pointer"
          })

          // Change it back to a pointer when it leaves.
          _self.map.on("mouseleave", "jm", function () {
            _self.map.getCanvas().style.cursor = ""
          })

          // Disable Map zoom using the Scroll wheel
          _self.map.scrollZoom.disable()

          // Disable Map rotation using right click + drag
          _self.map.dragRotate.disable()

          // Disable Map rotation using touch rotation gesture
          _self.map.touchZoomRotate.disableRotation()
        })
      })
      this.locate()
  },

  methods: {
    
    nearMe() {
      this.search_input = ''
      this.search_submit = false
      this.getDealers()
      this.error = false
      this.scrollToTop()
      let lnglat = [this.location.longitude.toFixed(4), this.location.latitude.toFixed(4)]
      this.map.flyTo({
        center: lnglat,
        zoom: 11,
      })
    },

    getDealers() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getDealers').then( () => {

          if (this.location) this.filtered_dealers = _.slice(this.dealers, 0, 6)
          else this.filtered_dealers = this.dealers
          this.loading = false
          return resolve()
        }).catch( (error) => {
          window.console.log(error)
          this.error = "Liikkeitä ei löytynyt"
          this.loading = false
          return reject()
        });
      });
    },

    showMore() {
      this.filtered_dealers =_.slice(this.dealers, 0, this.filtered_dealers.length + 10)
    },

    search() {
      this.search_submit = true
      this.error = false
      this.getDealers().then(() => {
        var self = this
        this.filtered_dealers = _.filter(this.dealers, function(dl) {
          return (
            JSON.stringify(dl)
              .toLowerCase()
              .indexOf(self.search_input.toLowerCase()) !== -1
          )
        })

          this.loading = false

        }).catch( (error) => {
          console.error(error)
          this.error = "Hakemaasi sijaintia ei löytynyt."
        })
      },

      locate() {
        this.$store.dispatch('findLocation').then( () => {
          this.search_location = this.location;
          this.getDealers();
          this.search_input = '';
          this.search_submit = false;
        }).catch( (error) => {
          this.getDealers();
          console.error(error)
          this.error = "Sijaintia ei voitu hakea"
          
        });
      },

    mapRoute(dealer) {
      const from = { lat: this.search_location.latitude, lng: this.search_location.longitude }
      const to = { lat: dealer.addresses[0].geoLocation.lat, lng: dealer.addresses[0].geoLocation.lon }
      window.open('https://www.google.com/maps/dir/?api=1&origin=' + from.lat + ',' + from.lng + '&destination=' + to.lat + ',' + to.lng, '_blank')
    },


    centerMap(coordinates) {
      this.scrollToTop()
      this.map.flyTo({
        center: coordinates,
        zoom: 12,
      })
    },

    getPhone(dealer) {
      return dealer.phoneNumber.Workshop
        ? dealer.phoneNumber.Workshop[0]
        : dealer.defaultPhoneNumber
    },

    getEmail(dealer) {
      return dealer.email.Workshop
        ? dealer.email.Workshop[0]
        : dealer.defaultEmail
    },

    runBind(dealers_bind) {
      this.jmGeoJSON = { jm: [] } //type: "FeatureCollection",

      // marker arrays

      dealers_bind.forEach((e) => {
        const jmData = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(e.defaultAddress.geoLocation.lon),
              parseFloat(e.defaultAddress.geoLocation.lat),
            ],
          },
          properties: {
            companyName: e.name.replace(/&amp;/g, '&'),
            address: `${e.addresses[0].addressLine1}, ${e.addresses[0].zip} ${e.addresses[0].city}`,
            phone: e.defaultPhoneNumber,
            email: e.defaultEmail,
            webAddress: e.url.WebsiteURL,
            operations: e.operations,
            marker: null,
          },
        };

        if (
          e.operations.includes("WorkShop") ||
          e.operations.includes("BodyShop")
        ) {
          jmData.properties.marker = "marker-liike"
        }
        if (e.operations.includes("ShowRoom")) {
          jmData.properties.marker = "marker-liike"
        }
        if (e.operations.includes("BusinessCenter")) {
          jmData.properties.marker = "marker-liike"
        }
        this.jmGeoJSON.jm.push(jmData)
      });
    },
  }
};
</script>
<style>
#marker-selector {
  background: #eee;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  padding: 10px;
  position: absolute;
  display: block;
  right: 70px;
  top: 100px;
  z-index: 1;
}

#marker-selector-mobile {
  display: none;
}

#marker-selector h6 {
  margin-left: 7px;
  max-width: 150px;
  display: none;
}

#marker-selector .marker-filter {
  font-size: 13px;
  min-height: 40px;
  max-width: 200px;
  padding: 10px 0 10px 50px;
}

#dealer_infodata {
  list-style-type: none;
}

@media (max-width: 768px) {
  #marker-selector {
    padding: 10px 40px 10px 10px;
    position: relative;
    top: 10px;
    right: 0;
  }
  #marker-selector .marker-filter {
    font-size: 13px;
    min-height: 40px;
    max-width: 100%;
    padding: 10px 0px 10px 40px;
  }
}

hr {
  height: 1px;
  color: #dad8d8;
  background-color: #dad8d8;
  border: none;
}

.mapboxgl-popup {
  max-width: 330px !important;
  position: absolute;
  top: 10%;
  left: 5%;
  transform: none !important;
}

.mapboxgl-popup .mapboxgl-popup-content {
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  /* transform: translate(-100%, -50%)!important; */
  background: #fff;
  border-radius: 2px;
  width: 330px !important;
  height: 350px !important;
  padding: 10px;
  z-index: 999;
}
.mapboxgl-popup-tip {
  display: none !important;
}
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}
</style>