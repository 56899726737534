import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './js/App.vue'
import router from './js/router/index'
import './scss/app.scss'

import { actions } from './js/store/actions'
import { mutations } from './js/store/mutations'
import { getters } from './js/store/getters'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
    state() {
      return {
        location: null,
        dealers: null,
        address: null,
      }
    },

    actions: actions,
    mutations: mutations,
    getters: getters,

    strict: debug,
})

createApp(App).use(router).use(store).mount('#app')