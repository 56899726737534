<template>
  <main>
    <AppHeader />

    <section class="container-fluid bg-white">
      <div class="container pt-3 pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <h1 class="mb-1 main-header">Törmäys, ulosajo tai eläinkolari</h1>
            <p>Ohjeet vahingon sattuessa:</p>
          </div>
        </div>
      </div>

      <div class="container pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <div class="card">
              <img
                src="img/tormays_tilannekartoitus.jpg"
                alt="Tarkista aluksi, onko joku loukkaantunut. Jos on, soita 112 ja pyydä ensiapua ja poliisi."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>
                  Selvitä ensimmäiseksi, onko kukaan loukkaantunut. Jos
                  törmäyksestä on aiheutunut henkilövahinkoja, soita 112 ja
                  pyydä onnettomuuspaikalle ensiapu ja poliisi. Jos vahinko on
                  vähäinen esim. raapaisu betoniporsaaseen, poliisia ei tarvita.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_varoituskolmio.jpg"
                alt="Varota muita! Laita varoituskolmio tielle noin 100 m ennen kolaripaikkaa."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Varoita muita! Aseta varoituskolmio tien reunaan reilu 100
                  metriä ennen kolaripaikkaa.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_elaimeen.jpg"
                alt="Soita huomattavasta eläinvahingosta (esim. suurempi kuin supikoira) hätäkeskukseen 112. Loukkaantuneet kotieläimet on toimitettava eläinlääkärille. Merkitse onnettomuuspaikka."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>
                  Törmäsitkö eläimeen? Ilmoita huomattavasta (esim. suurempi
                  kuin supikoira) eläinvahingosta aina hätäkeskukseen ja
                  poliisille (112). Loukkaantuneet kotieläimet on toimitettava
                  eläinlääkärille. Hätäkeskuksesta saat lisäapua tilanteen
                  hoitamiseen. Merkitse onnettomuuspaikka helpottaaksesi
                  loukkaantuneen eläimen etsimistä.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_kuvaa_kolari.jpg"
                alt="Kuvaa kännykkäkameralla kolaritilanne. Ota reilusti kuvia."
              />
              <div class="caption with-order">
                <span class="order">4</span>
                <p>
                  Dokumentoi törmäyspaikka valokuvin esim. yleiskuva,
                  mahdolliset jarrutusjäljet, törmäyspaikka, auton vahingot. Ota
                  reilusti kuvia.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_hinausauto.jpg"
                alt="Tarvitaanko hinausauto? Jos tarvitset, soita Toyota-palvelunumeroon 0800 13 663."
              />
              <div class="caption with-order">
                <span class="order">5</span>
                <p>
                  Mikäli autosi on vahingoittunut siten, ettei matkan jatkaminen
                  onnistu, tilaa hinausauto Toyota-palvelunumerosta 0800 13 663.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_varoitusvalo.jpg"
                alt="Turvalaitteet ovat saattaneet törmäyksessä vaurioitua. Tarkistuta ne vauriotarkastuksessa."
              />
              <div class="caption with-order">
                <span class="order">6</span>
                <p>
                  Törmäystilanteessa autosi turvalaitteet ovat saattaneet
                  vahingoittua. Mikäli autosi on ajokunnossa, varaa aika ja tule
                  Vauriotarkastukseemme. Näin varmistat, että autosi
                  turvalaitteet toimivat luotettavalla tavalla.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_kunnostus.jpg"
                alt="Korjauta autosi laadukkaasti Toyota-korikorjaamossa. Se on tärkeää turvallisuutesi ja autosi jälleenmyyntiarvon kannalta."
              />
              <div class="caption with-order">
                <span class="order">7</span>
                <p>
                  Korjauta autosi laadukkaasti. Toyota-korikorjaamossa. Se on
                  tärkeää sekä turvallisuutesi että autosi jälleenmyyntiarvon
                  vuoksi.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_vahinkoilmoitus.jpg"
                alt="Toyotan vahinkotarkastuspisteessä sinua autetaan myös vahinkoilmoituksen tekemisessä."
              />
              <div class="caption with-order">
                <span class="order">8</span>
                <p>
                  Tee vauriotarkastuksen yhteydessä vahinkoilmoitus
                  vakuutusyhtiöön. Toyota-liike auttaa sinua ilmoituksen teossa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AppFooter
      v-if="isMobile()"
      :buttonLocation="false"
      :buttonMaintenance="false"
    />

    <DesktopFooter v-else />
    <FooterVakuutus v-if="!isMobile()" />
    <FooterKorjaamoluotto v-if="!isMobile()" />
    <BackToTop v-if="!isMobile()" />
  </main>
</template>

<script>
import useGlobalHelper from "./../composables/useGlobalHelper";
import AppHeader from "./../components/AppHeader";
import AppFooter from "./../components/AppFooter";
import DesktopFooter from "./../components/DesktopFooter";
import FooterVakuutus from "./../components/FooterVakuutus";
import FooterKorjaamoluotto from "./../components/FooterKorjaamoluotto";
import BackToTop from "./../components/BackToTop";

export default {
  name: "Tormays",
  setup() {
    const { isMobile, isIframe } = useGlobalHelper();
    return {
      isMobile,
      isIframe,
    };
  },
  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    DesktopFooter: DesktopFooter,
    BackToTop: BackToTop,
    FooterVakuutus: FooterVakuutus,
    FooterKorjaamoluotto: FooterKorjaamoluotto,
  },
};
</script>
