<template>
  <section id="footer-korjaamoluotto">
    <div class="container text-white py-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="pb-2">Huoltorahoitus helpottaa elämääsi!</h3>
          <p class="text-white">
            Rypistyikö pelti, isompi huolto edessä? Vai haluatko uuden
            rengassarjan kevytmetallivanteineen? Huoltorahoituksella jaksotat
            yllättävät menot sinulle sopiviin eriin pidemmälle ajalle. Taloutesi
            pysyy tasapainossa ja elämä hymyilee.
          </p>
          <a href="https://www.toyota.fi/rahoitus/toyota-huoltorahoitus.json" target="_blank" rel="noopener">
            <strong>Lue lisää Toyota Huoltorahoituksesta
              <i class="fa fa-chevron-right"></i>
            </strong>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterKorjaamoluotto",
};
</script>
