import * as types from './mutation-types'
import axios from 'axios'
import helper from './../plugins/Helper'
import _ from 'lodash'
import config from './../plugins/config'

export const actions = {

  findLocation({ state, commit }) {

    return new Promise((resolve, reject) => {
      if (state.location) return resolve()

      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            commit(types.SET_LOCATION, pos.coords)
            return resolve()
          },
          (err) => {
            return reject(err)
          }, {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0
        });
      } else {
        return reject("Sijaintia ei voitu hakea")
      }
    })
  },

  getDealers({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.dealers) return resolve()

      axios.get("https://a2d-proxy.lib.toyota.fi/api/data/by_city/", {
        headers: {
          Authorization: config.a2dAuth,
          },
      })
      .then(function (response) {

        var dealers = response.data

        // Set location object for dealers
        for (var i in dealers) {
          dealers[i].location = {
            lat: Number(dealers[i].defaultAddress.geoLocation.lat),
            lng: Number(dealers[i].defaultAddress.geoLocation.lon)
          };

          if (state.location) {
            dealers[i].distance = helper.getDistance(
              dealers[i].location.lat,
              dealers[i].location.lng,
              state.location.latitude,
              state.location.longitude,
            )
          }
        }

        // Sort dealers alphabetically/distance from user
        if (state.location) dealers = _.sortBy(dealers, 'distance')
        else dealers = _.sortBy(dealers, 'name')

        commit(types.SET_DEALERS, dealers)

        return resolve();
      })
      .catch(function (error) {
        return reject(error)
      })
    })
  }
}