<template>
  <main>
    <AppHeader />

    <section class="container-fluid bg-white">
      <div class="container pt-3 pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <h1 class="mb-1 main-header">Renkaan puhkeaminen</h1>
            <p class="">Ohjeet vahingon sattuessa:</p>
          </div>
        </div>
      </div>

      <div class="container pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <div class="card">
              <img
                src="img/rengasrikko_pysahdy_heti.jpg"
                alt="Pysähdy välittömästi tuntiessasi epätavallista tärinää tai ohjausongelmia. Jarruta rauhallisesti ja etsi rauhallinen paikka, jossa voit turvallisesti tutkia tilannetta."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>
                  Pysähdy välittömästi tuntiessasi epätavallista tärinää tai
                  ohjausongelmia. Jarruta rauhallisesti ja etsi rauhallinen
                  paikka, jossa voit turvallisesti tutkia tilannetta.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/rengasrikko_vararengas_paikkausaine.jpg"
                alt="Autossasi on joko vararengas tai renkaanpaikkausaine väliaikaiseen paikkaamiseen. Yksityiskohtaiset toimintaohjeet löydät Omistajan oppaasta."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Autossasi on joko vararengas tai renkaanpaikkausaine
                  väliaikaiseen paikaamiseen. Yksityiskohtaiset toimintaohjeet
                  löydät autosi käyttöohjekirjasta (Omistajan oppaasta) Alta
                  löydät yleiset ohjeet kumpaankin tilanteeseen.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/rengasrikko_60_kmh.jpg"
                alt="Vararenkaan tai väliaikaisesti paikatun renkaan turvallisuus ei vastaa normaalia rengasta eikä ajonopeus saa ylittää 80 km/h. Vie puhjennut rengas aina Toyota-rengasammattilaisen paikattavaksi."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>
                  Vararenkaalla tai väliaikaisesti paikatulla renkaalla on
                  ajettava erittäin rauhallisesti. Turvallisuus ei vastaa
                  normaalia rengasta eikä ajonopeus saa ylittää 80 km/h. Vie
                  puhjennut rengas aina Toyota-rengasammattilaisen
                  tarkastettavaksi ja paikattavaksi.
                </p>
              </div>
            </div>

            <h4 class="py-2">Paikkausainesprayn käyttö</h4>

            <div class="card">
              <img
                src="img/paikkausspray_reika.jpg"
                alt="Paikallista reikä ja poista mahdollinen vieras esine."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>Paikallista reikä ja poista mahdollinen vieras esine.</p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/paikkausspray_kompressori.jpg"
                alt="Jos autosi paikkausaine toimii kompressorilla, katso tarkemmat ohjeet autosi ohjekirjasta ja täytä rengas ohjeenmukaiseen paineeseen."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Jos autosi paikkausaine toimii kompressorilla, katso tarkemmat
                  ohjeet autosi ohjekirjasta. Kiinnitä mukana oleva
                  paikkausainespray kompressoriin, kytke virtajohto virran
                  ulosoton pistokkeeseen ja täytä rengas ohjeenmukaiseen
                  paineeseen.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/paikkausspray_painepullo.jpg"
                alt="Jos sinulla on erillinen pakkausainespraypullo, tutustu sen käyttöohjeisiin ja täytä rengas."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>
                  Jos sinulla on erillinen paikkausainespraypullo, tutustu sen
                  käyttöohjeisiin ja täytä rengas.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/paikkausspray_tarkista_paine.jpg"
                alt="Tarkista muutaman ajokilometrin jälkeen, että paine on pitänyt. Jatka ajoa rauhallisella nopeudella."
              />
              <div class="caption with-order">
                <span class="order">4</span>
                <p>
                  Tarkista muutaman ajokilometrin jälkeen, että paine on
                  pitänyt. Jatka ajoa rauhallisella nopeudella.
                </p>
              </div>
            </div>

            <h4 class="py-2">Vararenkaan vaihtaminen</h4>

            <div class="card">
              <img
                src="img/vararengas_kasijarru_paalle.jpg"
                alt="Varmista, ettei auto pääse liikkumaan. Laita käsijarru ja vaihde päälle."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>
                  Varmista, ettei auto pääse liikkumaan. Laita käsijarru ja
                  vaihde päälle.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/vararengas_tunkin_nostokohdat.jpg"
                alt="Tarkista ohjekirjasta tunkin käyttöohje ja nostokohdat autossa."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Tarkista ohjekirjasta tunkin käyttöohje ja nostokohta autossa.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/vararengas_pultit.jpg"
                alt="Löysää pultteja ensin vähän. Nosta auto tunkilla ja avaa pultit kokonaan."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>
                  Löysää pultteja ensin vähän. Nosta auto tunkilla ja avaa
                  pultit kokonaan.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/vararengas_asenna_kirista.jpg"
                alt="Laita vararengas paikoilleen ja väännä pultit kiinni. Aluksi sormin. Muista ristikiristys."
              />
              <div class="caption with-order">
                <span class="order">4</span>
                <p>
                  Laita vararengas paikoilleen ja väännä pultit kiinni. Aluksi
                  sormin. Muista ristikiristys.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/vararengas_laske_auto_kirista.jpg"
                alt="Kun olet laskenut auton maahan, kiristä pultit vielä uudelleen."
              />
              <div class="caption with-order">
                <span class="order">5</span>
                <p>
                  Kun olet laskenut auton maahan, kiristä pultit vielä
                  uudelleen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AppFooter
      v-if="isMobile()"
      :buttonLocation="false"
      :buttonMaintenance="false"
    />

    <DesktopFooter v-else />
    <FooterVakuutus v-if="!isMobile()" />
    <FooterKorjaamoluotto v-if="!isMobile()" />
    <BackToTop v-if="!isMobile()" />
  </main>
</template>

<script>
import useGlobalHelper from "./../composables/useGlobalHelper";
import AppHeader from "./../components/AppHeader";
import AppFooter from "./../components/AppFooter";
import DesktopFooter from "./../components/DesktopFooter";
import FooterVakuutus from "./../components/FooterVakuutus";
import FooterKorjaamoluotto from "./../components/FooterKorjaamoluotto";
import BackToTop from "./../components/BackToTop";

export default {
  name: "Rengasrikko",
  setup() {
    const { isMobile, isIframe } = useGlobalHelper();
    return {
      isMobile,
      isIframe,
    };
  },
  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    DesktopFooter: DesktopFooter,
    FooterVakuutus: FooterVakuutus,
    FooterKorjaamoluotto: FooterKorjaamoluotto,
    BackToTop: BackToTop,
  },
};
</script>
