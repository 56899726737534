<template>
  <main>
    <AppHeader />

    <section class="container-fluid bg-white">
      <div class="container pt-3 pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <h1 class="mb-1 main-header">Naarmut, kolhut ja ilkivalta</h1>
            <p class="">Toiminta eri tilanteissa</p>
          </div>
        </div>
      </div>

      <div class="container pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <div class="card">
              <img
                src="img/kolhut_peili_rikki.jpg"
                alt="Jos ilkivalta ei vaadi välittömiä toimenpiteitä poliisilta, tee siitä sähköinen rikosilmoitus poliisille. Rikosilmoituksen jäljennös tarvitaan aina vakuutusyhtiötä varten."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>
                  Jos ilkivalta ei vaadi välittömiä toimenpiteitä poliisilta,
                  tee siitä sähköinen rikosilmoitus poliisille. Voit myös käydä
                  poliisilaitoksella. Rikosilmoituksen jäljennös tarvitaan aina
                  vakuutusyhtiötä varten.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_kunnostus.jpg"
                alt="Korjauta autosi laadukkaasti Toyota-korikorjaamossa. Se on tärkeää turvallisuutesi ja autosi jälleenmyyntiarvon kannalta."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Korjaamattomat vauriot saattavat aiheuttaa lisäharmeja ja
                  alentavat autosi jälleenmyyntiarvoa. Korjauta autosi
                  laadukkaasti Toyota-korikorjaamossa. Se on tärkeää sekä oman
                  turvallisuutesi että autosi jälleenmyyntiarvon vuoksi.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/kolhut_naarmuteippaus.jpg"
                alt="Korjauta pienet naarmut Toyota-liikkeessä. Sieltä löydät myös autosi väriin sopivan korjausmaalin ja Stickerfix-tarran, joilla voit korjata itsekin naarmuja näkymättömiin."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>
                  Korjauta pienet naarmut Toyota-liikkeessä. Sieltä löydät myös
                  autosi väriin sopivan korjausmaalin ja
                  <a
                    href="https://www.toyota.fi/omistajalle/lisavarusteet/huolehdi-autostasi.json"
                    >Stickerfix-tarran</a
                  >, joilla voit korjata itsekin naarmuja näkymättömiin.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/kolhut_naarmu_kyljessa.jpg"
                alt="Isommat naarmut ja kolhut kannattaa korjata heti. Toyota-korikorjaamossa autosi korjataan laadukkaasti alkuperäisillä Toyota-osilla."
              />
              <div class="caption with-order">
                <span class="order">4</span>
                <p>
                  Isommat naarmut ja kolhut kannattaa korjata heti.
                  Toyota-korikorjaamossa autosi korjataan laadukkaasti
                  alkuperäisillä Toyota-osilla.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_vahinkoilmoitus.jpg"
                alt="Toyotan vahinkotarkastuspisteessä sinua autetaan myös vahinkoilmoituksen tekemisessä."
              />
              <div class="caption with-order">
                <span class="order">5</span>
                <p>
                  Ennen kuin korjaustöihin ryhdytään, tee ilmoitus vahingosta
                  vakuutusyhtiöön. Ilkivallan kyseessä ollessa tarvitset kopion
                  rikosilmoituksesta. Selvitä vakuutuksesi kattavuus. Esim.
                  <a href="https://www.toyota.fi/palvelut/vakuutus/index.json"
                    >Toyota vakuutuksen</a
                  >
                  Täyskaskossa et menetä bonuksia parkkihallikolhujen
                  korvauksesta.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AppFooter
      v-if="isMobile()"
      :buttonLocation="false"
      :buttonMaintenance="false"
      :buttonCall="false"
    />

    <DesktopFooter v-else />
    <FooterVakuutus v-if="!isMobile()" />
    <FooterKorjaamoluotto v-if="!isMobile()" />
    <BackToTop v-if="!isMobile()" />
  </main>
</template>

<script>
import useGlobalHelper from "./../composables/useGlobalHelper";
import AppHeader from "./../components/AppHeader";
import AppFooter from "./../components/AppFooter";
import DesktopFooter from "./../components/DesktopFooter";
import FooterVakuutus from "./../components/FooterVakuutus";
import FooterKorjaamoluotto from "./../components/FooterKorjaamoluotto";
import BackToTop from "./../components/BackToTop";

export default {
  name: "Naarmut",
  setup() {
    const { isMobile } = useGlobalHelper();
    return {
      isMobile,
    };
  },
  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    DesktopFooter: DesktopFooter,
    FooterVakuutus: FooterVakuutus,
    FooterKorjaamoluotto: FooterKorjaamoluotto,
    BackToTop: BackToTop,
  },
};
</script>
