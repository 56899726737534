<template>
  <section id="footer-mobile">
    <div
      id="footer"
      class="container text-align--center text-white py-3"
      :class="{ 'hide-text': !showText }"
    >
      <button
        v-if="buttonCall"
        @click="call('0800 13 663')"
        class="btn btn-call"
      >
        <i
          class="icon-phone"
          :class="{ 'pull-left': showText }"
          aria-hidden="true"
        ></i>
        <span>Soita 24/7</span>
      </button>

      <a
        v-if="buttonMaintenance"
        class="btn btn-t1-primary"
        href="https://www.toyota.fi/omistajalle/huolto/huoltovaraus.json"
        target="_top"
      >
        <i
          class="icon-service-and-maintenance"
          :class="{ 'pull-left': showText }"
          aria-hidden="true"
        ></i>
        <span>Varaa huolto Toyotallesi</span>
      </a>

      <a
        v-if="buttonCheck && checkEnabled"
        href="https://toyotahuoltovaraus.fi/varaa/vauriotarkastus"
        target="_blank"
        class="btn"
      >
        <i
          class="icon-calendar"
          :class="{ 'pull-left': showText }"
          aria-hidden="true"
        ></i>
        <span>Varaa vauriotarkastus</span>
      </a>

      <router-link v-if="buttonDealers" class="btn" to="/liike">
        <i
          class="icon-map-marker"
          :class="{ 'pull-left': showText }"
          aria-hidden="true"
        ></i>
        <span>Etsi lähin Toyota-korikorjaamo</span>
      </router-link>

      <router-link v-if="buttonLocation" to="/sijainti" class="btn">
        <i
          class="icon-locate"
          :class="{ 'pull-left': showText }"
          aria-hidden="true"
        ></i>
        <span>Katso sijaintisi</span>
      </router-link>
    </div>
  </section>
</template>

<script>
import useGlobalHelper from './../composables/useGlobalHelper'

export default {
  name: "AppFooter",
  setup() {
    const { call, checkEnabled } = useGlobalHelper();
    return {
      call,
      checkEnabled
    };
  },
  props: {
    showText: {
      type: Boolean,
      default: true,
    },

    /** Buttons **/
    buttonCall: {
      type: Boolean,
      default: true,
    },

    buttonMaintenance: {
      type: Boolean,
      default: true,
    },

    buttonCheck: {
      type: Boolean,
      default: true,
    },

    buttonDealers: {
      type: Boolean,
      default: true,
    },

    buttonLocation: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
