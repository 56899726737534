<template>
  <main>
    <AppHeader />
    <section
      id="mapContainer"
      class="basemap"
      style="width: 100%; height: 450px"
    ></section>
    <section
      v-if="loading"
      class="container-fluid text-center c-pt-half fixed-top"
    >
      <p>
        <i
          class="fa fa-circle-o-notch fa-spin fa-2x text-red"
          aria-hidden="true"
        ></i>
        <span class="sr-only">Loading...</span>
      </p>
    </section>
    
    <section
      class="container-fluid text-center bg-white py-3"
    >
      <h3 class="mb-2">Sijaintisi</h3>
      <dl>
        <dt>Koordinaatit</dt>
        <dd class="c-loose" id="info">
        </dd>
      </dl>
    </section>

    <section v-if="error" class="container-fluid text-center bg-white py-2">
      <h2 class="mb-1">Sijaintiasi ei löytynyt</h2>
      <p>
        Varmista, että laitteessasi on sijaintipalvelut käytössä ja yritä
        uudelleen.
      </p>
    </section>

    <AppFooter
      :showText="false"
      :buttonMaintenance="false"
      :buttonCheck="false"
      :buttonDealers="false"
      :buttonLocation="false"
    />

    <BackToTop v-if="!isMobile()" />
  </main>
</template>

<script>
import useGlobalHelper from './../composables/useGlobalHelper'
//import { mapState } from 'vuex'
import config from "./../plugins/config"
import AppHeader from './../components/AppHeader'
import AppFooter from './../components/AppFooter'
import BackToTop from './../components/BackToTop'
import mapboxgl from 'mapbox-gl'

export default {
  name: "Sijainti",
  setup() {
    const { isMobile, isIframe } = useGlobalHelper();
    return {
      isMobile,
      isIframe,
    };
  },
  data() {
    return {
      //map
      map: null,
      accessToken: config.mapBoxToken,
      mapStyle: "mapbox://styles/mapbox/streets-v11",
      zoom: 3.5,
      center: { lat: 65.500, lng: 26.000 },

      location: '',

      loading: true,
      error: false,
    }
  },

  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    BackToTop: BackToTop,
  },

  created() {
    this.loading = false
  },

  mounted() {
    // create map
    mapboxgl.accessToken = this.accessToken;
    this.map = new mapboxgl.Map({
      container: "mapContainer",
      style: this.mapStyle,
      minZoom: 3.5,
      maxZoom: 15,
      center: this.center,
      zoom: this.zoom,
    })
    // add Navigation controls
    this.map.addControl(new mapboxgl.NavigationControl({
      showCompass: false,
    }), "top-left")

    // add Geolocate 
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
      })
    // add the control to the map.
    this.map.addControl(geolocate, "top-left")
    this.map.on('load', function() {
      geolocate.trigger()
    })
    geolocate.on('geolocate', function(e) {
      const lon = e.coords.longitude.toFixed(4)
      const lat = e.coords.latitude.toFixed(4)
      const position = [lat + ", " + lon]
      document.getElementById('info').innerHTML = position
    })

    // disable map zoom using scroll wheel
    this.map.scrollZoom.disable()

    // disable map rotation using right click + drag
    this.map.dragRotate.disable()

    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation()

  }
}
</script>