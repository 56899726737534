<template>
  <section id="back-to-top">
    <div class="container text-white py-2">
      <span class="scroll-top" @click="scrollToTop()"
        ><i class="fa fa-fw fa-chevron-up" aria-hidden="true"></i> &nbsp;
        Takaisin ylös</span
      >
      <a
        class="pull-right"
        href="https://www.toyota.fi/about/kayttoehdot.json"
        target="_blank"
        >Käyttöehdot</a
      >
    </div>
  </section>
</template>

<script>
import useGlobalHelper from './../composables/useGlobalHelper'

export default {
  name: "BackToTop",
    setup() {
    const { scrollToTop } = useGlobalHelper();
    return {
      scrollToTop
    };
  }
};
</script>
