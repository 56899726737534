<template>
  <main>
    <section id="home">
      <div class="container text-align--center text-white pb-3 pt-5">
        <div v-if="!isIframe()" class="logo py-3">
          <img src="img/toyota-logo-@2x.png" alt="Toyota" />
        </div>

        <h1 class="mb-3 intro">
          Toyota auttaa ja neuvoo sinua vahingon sattuessa
        </h1>

        <div v-if="isMobile()">
          <button
            type="button"
            class="btn btn-call"
            @click="call('0800 13 663')"
          >
            <i class="icon-phone pull-left" aria-hidden="true"></i> Soita 24/7
          </button>

          <router-link tag="button" class="btn btn-t1-secondary" to="/sijainti">
            <i class="icon-locate pull-left" aria-hidden="true"></i> Katso
            sijaintisi
          </router-link>

          <router-link tag="button" class="btn btn-t1-secondary" to="/liike">
            <i class="icon-map-marker pull-left" aria-hidden="true"></i> Etsi
            lähin Toyota-korikorjaamo
          </router-link>

          <a
            v-if="checkEnabled"
            href="https://toyotahuoltovaraus.fi/varaa/vauriotarkastus"
            target="_blank"
            class="btn btn-t1-secondary"
          >
            <i class="icon-calendar pull-left" aria-hidden="true"></i> Varaa
            vauriotarkastus
          </a>
        </div>

        <div v-else>
          <div class="intro-number text--center mb-3">
            <i class="icon-phone" aria-hidden="true"></i> Soita 24/7
            maksuttomaan numeroon
            <span @click="call('0800 13 663')" class="call-number"
              >0800 13 663</span
            >
          </div>

          <!-- <button
            v-if="chatEnabled"
            type="button"
            class="btn btn-t1-secondary"
            @click="openChat"
          >
            <i class="icon-live-chat pull-left" aria-hidden="true"></i> Avaa
            chat
          </button> -->

          <router-link tag="button" class="btn btn-t1-secondary" to="/liike">
            <i class="icon-map-marker pull-left" aria-hidden="true"></i> Etsi
            lähin Toyota-korikorjaamo
          </router-link>

          <a
            v-if="checkEnabled"
            href="https://toyotahuoltovaraus.fi/varaa/vauriotarkastus"
            target="_blank"
            class="btn"
          >
            <i class="icon-calendar pull-left" aria-hidden="true"></i> Varaa
            vauriotarkastus
          </a>
        </div>

        <h2 class="my-3 sub-header">Pikaohje onnettomuustilanteeseen</h2>

        <div class="container swiper">
          <swiper
            :slides-per-view="1"
            :space-between="0"
            navigation
          >
            <swiper-slide>
              <img
                src="img/tormays_tilannekartoitus.jpg"
                alt="Tarkista aluksi, onko joku loukkaantunut. Jos on, soita 112 ja pyydä ensiapua ja poliisi."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>
                  Selvitä ensimmäiseksi, onko kukaan loukkaantunut. Jos
                  törmäyksestä on aiheutunut henkilövahinkoja, soita 112 ja
                  pyydä onnettomuuspaikalle ensiapu ja poliisi.
                </p>
              </div>
            </swiper-slide>

            <swiper-slide>
              <img
                src="img/tormays_varoituskolmio.jpg"
                alt="Varota muita! Laita varoituskolmio tielle noin 100 m ennen kolaripaikkaa."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Varoita muita. Aseta varoituskolmio tien reunaan reilu 100
                  metriä ennen kolaripaikkaa.
                </p>
              </div>
            </swiper-slide>

            <swiper-slide>
              <img
                src="img/tormays_tiedot_talteen.jpg"
                alt="Ota talteen vastapuolen henkilötiedot, rekisterinumero, vakuutusyhtiö sekä todistajien tiedot."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>
                  Ota vastapuolen henkilötiedot talteen hänen ajokortistaan.
                  Kirjoita ylös rekisterinumero, vakuutusyhtiö ja muista ottaa
                  talteen myös mahdollisten todistajien henkilötiedot.
                </p>
              </div>
            </swiper-slide>

            <swiper-slide>
              <img
                src="img/tormays_ota_kuvia.jpg"
                alt="Dokumentoi törmäyspaikka, ota reilusti kuvia, kännykkäkamerakuvat riittävät."
              />
              <div class="caption with-order">
                <span class="order">4</span>
                <p>
                  Dokumentoi törmäyspaikka valokuvin esim. yleiskuva,
                  mahdolliset jarrutusjäljet, törmäyspaikka, auton vahingot. Ota
                  reilusti kuvia.
                </p>
              </div>
            </swiper-slide>

            <swiper-slide>
              <img
                src="img/tormays_hinausauto.jpg"
                alt="Tarvitaanko hinausauto? Jos tarvitset, soita Toyota-palvelunumeroon 0800 13 663."
              />
              <div class="caption with-order">
                <span class="order">5</span>
                <p>
                  Tarvitaanko hinausauto? Soita Toyota-palvelunumeroon 0800 13 663.
                </p>
              </div>
            </swiper-slide>

            <swiper-slide>
              <img
                src="img/tormays_vahinkotarkastus.jpg"
                alt="Toyotan vahinkotarkastuspisteessä sinua autetaan myös vahinkoilmoituksen tekemisessä."
              />
              <div class="caption with-order">
                <span class="order">6</span>
                <p>
                  Tuo autosi vahinkotarkastukseen. Toyota -liikkessä autamme
                  sinua myös vahinkoilmoituksen tekemisessä.
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container-fluid text-align--center bg-white py-3">
      <div class="container">
        <h2 class="mb-3 sub-header">Toimintaohjeita eri tilanteisiin</h2>

        <div class="row action-cols">
          <router-link
            tag="div"
            class="col col-6 mb-2 cursor--pointer"
            to="/kolari"
          >
            <img
              src="img/tormays_sininen_ja_punainen_auto.jpg"
              alt="Sinisen ja punaisen auton pieni peräänajo."
            />
            <div class="caption">
              <p>Kolari toisen auton kanssa »</p>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="col col-6 mb-2 cursor--pointer"
            to="/tormays"
          >
            <img src="img/elainkolari.jpg" alt="Eläinkolarissa toimi näin." />
            <div class="caption">
              <p>Törmäys, ulosajo, eläinkolari »</p>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="col col-6 mb-2 cursor--pointer"
            to="/naarmut"
          >
            <img
              src="img/kolhut_naarmu.jpg"
              alt="Pitkä naarmu auton kyljessä vaatii toimenpiteitä."
            />
            <div class="caption">
              <p>Naarmut, kolhut, ilkivalta »</p>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="col col-6 mb-2 cursor--pointer"
            to="/lasivahinko"
          >
            <img
              src="img/lasivahinko_kiveniskema.jpg"
              alt="Tuulilasi säröllä, pikaisen korjauksen tarpeessa."
            />
            <div class="caption">
              <p>Lasivahinko »</p>
            </div>
          </router-link>

          <router-link
            tag="div"
            class="col col-6 mb-2 cursor--pointer"
            to="/rengasrikko"
          >
            <img
              src="img/rengasrikko_rengas_tyhja.jpg"
              alt="Rengasrikko tulee aina yllättäen."
            />
            <div class="caption">
              <p>Renkaan puhkeaminen »</p>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <AppFooter
      v-if="isMobile()"
      :showText="false"
      :buttonMaintenance="false"
      :buttonCheck="false"
      :buttonDealers="false"
    />

    <DesktopFooter v-else />

    <BackToTop v-if="!isMobile()" />
  </main>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import useGlobalHelper from './../composables/useGlobalHelper'
import AppFooter from './../components/AppFooter'
import DesktopFooter from './../components/DesktopFooter'
import BackToTop from './../components/BackToTop'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

export default {
  name: "Index",
  setup() {
    const { isMobile, call, isIframe, checkEnabled } = useGlobalHelper()
    return {
      isMobile,
      call,
      isIframe,
      checkEnabled,
    }
  },
  transition: "index",
  components: {
    AppFooter,
    Swiper,
    SwiperSlide,
    DesktopFooter,
    BackToTop,
  }
}
</script>
<style>
.iframemargin {
  margin-top: 176px;
}
</style>