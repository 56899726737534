<template>
  <section id="top-bar" >
    <div class="container text-white py-1">
      <router-link tag="a" class="cursor--pointer go-back" to="/">
        <i class="fa fa-fw fa-chevron-left" aria-hidden="true"></i> Takaisin
      </router-link>
    </div>
  </section>
</template>

<script>

export default {
    name: 'AppHeader',
}
</script>
