import { createWebHistory, createRouter } from "vue-router"
import Index from "../views/Index.vue"
import Kolari from "../views/Kolari.vue"
import Lasivahinko from "../views/Lasivahinko.vue"
import Liike from "../views/Liike.vue"
import Naarmut from "../views/Naarmut.vue"
import Sijainti from "../views/Sijainti.vue"
import Tormays from "../views/Tormays.vue"
import Rengasrikko from "../views/Rengasrikko.vue"

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/kolari",
    name: "Kolari",
    component: Kolari,
    meta: { transitionName: 'slide' },
  },
  {
    path: "/lasivahinko",
    name: "Lasivahinko",
    component: Lasivahinko,
    meta: { transitionName: 'slide' },
  },
  {
    path: "/liike",
    name: "Liike",
    component: Liike,
    meta: { transitionName: 'slide' },
  },
  {
    path: "/naarmut",
    name: "Naarmut",
    component: Naarmut,
    meta: { transitionName: 'slide' },
  },
  {
    path: "/sijainti",
    name: "Sijainti",
    component: Sijainti,
    meta: { transitionName: 'slide' },
  },
  {
    path: "/tormays",
    name: "Tormays",
    component: Tormays,
    meta: { transitionName: 'slide' },
  },
  {
    path: "/rengasrikko",
    name: "Rengasrikko",
    component: Rengasrikko,
    meta: { transitionName: 'slide' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;