<template>
  <main>
    <AppHeader />

    <section class="container-fluid bg-white">
      <div class="container pt-3 pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <h1 class="mb-1 main-header">Lasivahinko</h1>
            <p class="">Vaihto vai korjaus</p>
          </div>
        </div>
      </div>

      <div class="container pb-2">
        <div class="row col-centered">
          <div class="col-12 col-md-10 col-lg-8">
            <div class="card">
              <img
                src="img/lasivahinko_kiveniskema_halkeama.jpg"
                alt="Kiveniskemä, pieni halkeama vai isompi vaurio? Toyota-korikorjaamo auttaa."
              />
              <div class="caption with-order">
                <span class="order">1</span>
                <p>Kiveniskemä, pieni halkeama vai isompi vaurio?</p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/lasivahinko_ensiapu.jpg"
                alt="Kiveniskemä tai halkeama pitää ensimmäiseksi puhdistaa ja suojata."
              />
              <div class="caption with-order">
                <span class="order">2</span>
                <p>
                  Kiveniskenän tai pienen halkeaman ensiapu on puhdistus ja
                  suojaus.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/lasivahinko_suojateippi.jpg"
                alt="Peitä vahingoittunut kohta teipin palalla."
              />
              <div class="caption with-order">
                <span class="order">3</span>
                <p>Peitä vahingoittunut kohta teipin palalla.</p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/lasivahinko_pida_kuivana.jpg"
                alt="Pidä vaurioitunut kohta mahdollisimman kuivana."
              />
              <div class="caption with-order">
                <span class="order">4</span>
                <p>Pidä vaurioitunut kohta mahdollisimman kuivana.</p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/lasivahinko_lasi_vaihdettava.jpg"
                alt="Lasi vaihdettava. Ota yhteys Toyota-korikorjaamoon."
              />
              <div class="caption with-order">
                <span class="order">5</span>
                <p>Lasi vaihdettava. Ota yhteys Toyota-korikorjaamoon.</p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_vahinkoilmoitus.jpg"
                alt="Toyotan vahinkotarkastuspisteessä sinua autetaan myös vahinkoilmoituksen tekemisessä."
              />
              <div class="caption with-order">
                <span class="order">6</span>
                <p>
                  Lasivahingon sattuessa ota yhteys Toyota-korjaamoon ja tee
                  tarvittaessa vahinkoilmoitus. Halutessasi Toyota-liike avustaa
                  vahinkoilmoituksen teossa. Mikäli autossasi on lasivakuutus,
                  lasin korjaus hoituu ilman omavastuuta.
                </p>
              </div>
            </div>

            <div class="card">
              <img
                src="img/tormays_vahinkotarkastus.jpg"
                alt="Katso tästä lähimmän Toyota-liikkeen yhteystiedot ja sovi jatkotoimista."
              />
              <div class="caption with-order">
                <span class="order">7</span>
                <p>
                  <router-link tag="a" to="/liike"
                    >Katso tästä lähimmän Toyota-liikkeen
                    yhteystiedot</router-link
                  >
                  ja sovi jatkotoimista.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AppFooter v-if="isMobile()" :buttonCall="false" :buttonLocation="false" />
    <DesktopFooter v-else />
    <FooterVakuutus v-if="!isMobile()" />
    <FooterKorjaamoluotto v-if="!isMobile()" />
    <BackToTop v-if="!isMobile()" />
  </main>
</template>

<script>
import useGlobalHelper from './../composables/useGlobalHelper'
import AppHeader from "./../components/AppHeader"
import AppFooter from "./../components/AppFooter"
import DesktopFooter from "./../components/DesktopFooter"
import FooterVakuutus from "./../components/FooterVakuutus"
import FooterKorjaamoluotto from "./../components/FooterKorjaamoluotto"
import BackToTop from "./../components/BackToTop"

export default {
  name: "Lasivahinko",
  setup() {
    const { isMobile, isIframe } = useGlobalHelper()
    return {
      isMobile,
      isIframe,
    };
  },
  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    DesktopFooter: DesktopFooter,
    BackToTop: BackToTop,
    FooterVakuutus: FooterVakuutus,
    FooterKorjaamoluotto: FooterKorjaamoluotto,
  },
};
</script>