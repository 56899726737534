<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in" v-on:before-enter="scrollToTop">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import useGlobalHelper from "./composables/useGlobalHelper";

export default {
  name: "App",
  setup() {
    const { scrollToTop } = useGlobalHelper();
    return {
      scrollToTop,
    };
  },
};
</script>

<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-6vh);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease-in-out;
}
</style>