import * as types from './mutation-types'

export const mutations = {

    [types.SET_LOCATION] (state, payload) {
        state.location = payload;
    },

    [types.SET_DEALERS] (state, payload) {
        state.dealers = payload;
    },

    [types.SET_ADDRESS] (state, payload) {
        state.address = payload;
    },
}
