import { onMounted, onUnmounted, ref } from "vue";

export default function () {

  const checkEnabled = true
  const width = ref(null)

  function resize() {
    width.value = window.innerWidth
  }

  onMounted(() => {
    resize()
    window.addEventListener("resize", resize)
  })

  onUnmounted(() => {
    window.removeEventListener("resize", resize)
  })

  function isMobile() {
    if (width.value <= 768) {
      return true
    }
    else {
      return false
    }
  }

  function call(number) {
    window.location.href = 'tel:' + number

    // sendAnalyticsEvent({
    // 	type: 'event',
    // 	category: 'Phone call',
    // 	action: 'click',
    // 	label: number,
    // });
  }

  function email(email) {
    window.location.href = 'mailto:' + email
  }

  function scrollToTop() {
    window.scroll(0, 0)
  }

  function isIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  return {
    checkEnabled,
    isMobile,
    call,
    email,
    scrollToTop,
    isIframe,
    resize
  }
}
