<template>
  <section id="footer-vakuutus">
    <div class="container text-white py-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="pb-2">Toyota Vakuutus. Onni onnettomuudessa!</h3>
          <p class="text-white">
            Autoillessa sattuu ja tapahtuu - onneksi yleensä pieniä harmittomia
            asioita. Mutta niidenkin korjaaminen maksaa! Toyota Vakuutus suojaa
            sinua ylimääräiseltä rahanmenolta. Poimi autosi kaskoon sopivia
            lisäturvia, kuten pysäköinti-, ilkivalta-, lemmikki- tai lasiturva,
            ja ajat turvallisin mielin.
          </p>
          <a href="https://www.toyota.fi/palvelut/vakuutus/index.json" target="_blank" rel="noopener">
            <strong>Lue lisää Toyota Vakuutuksesta
              <i class="fa fa-chevron-right"></i>
            </strong>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterVakuutus",
};
</script>
